<template>
  <div id="app">
    <div :style="'width:' +widthI+ '; height:' + heightI">
      <div :id="videoId"></div>
    </div>
  </div>
</template>

<script>
import Player from 'xgplayer/dist/simple_player';
import 'xgplayer';
import FlvPlayer from "xgplayer-flv";
import hlsPlayer from 'xgplayer-hls.js';
import volume from 'xgplayer/dist/controls/volume';
import playbackRate from 'xgplayer/dist/controls/playbackRate';

export default {
  props: {
    videoId: {
      type: String,
      default: "charon"
    },
    sourceI: {
      type: String,
      default: null
    },
    widthI: {
      type: String,
      default: "100%"
    },
    "fitVideoSize": {
      type: String,
      default: null
    },
    heightI: {
      type: String,
      default: "300px"
    },
    widthC: {
      type: String,
      default: null
    },
    heightC: {
      type: String,
      default: null
    },
    fluid: {
      type: String,
      default: "false"
    }
  },
  data() {
    return {
      player: null
    }
  },
  methods: {
    initPlayer(url, bgImg) {
      const config = {
        id: this.videoId, // 上面容器的id选择器
        "url": url,
        // "fluid": this.fluid,
        "poster": "https://www.toopic.cn/public/uploads/small/1658043292312165804329268.png", // 海报
        "playbackRate": [0.75, 1, 1.5, 2, 2.5, 3, 3.5], // 播放速率
        "download": true, // 下载
        "keyShortcut": "on",
        "volume": 0,
        keyShortcutStep: { //设置调整步长
          currentTime: 10, //播放进度调整步长，默认10秒
          volume: 0.1 //音量调整步长，默认0.1
        },
        controlPlugins: [
          volume,
          playbackRate
        ],
      }
      if (this.fitVideoSize != null) {
        config["fitVideoSize"] = this.fitVideoSize
      }
      if (this.fluid == "false") {
        if (this.widthC != null) {
          config['width'] = Number(this.widthC)
        }
        if (this.heightC != null) {
          config['height'] = Number(this.heightC)
        }
      } else {
        config['fluid'] = true
      }
      const format = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
      if (format == 'm3u8') {
        delete config.controlPlugins
        this.player = new hlsPlayer(config);
      } else if (format == 'mp4') {
        this.player = new Player(config);
      } else if (format == 'flv') {
        this.player = new FlvPlayer(config);
      }

    },
    /**
     * 切换源
     * @param url 播放地址
     */
    switchingSrc(url) {
      if (this.player != null) {
        this.player.destroy(true);
      }
      this.initPlayer(url);
    },
    /**
     * 同源播放
     * @param url 播放地址
     */
    homologousSrc(url) {
      this.player = url;
    }
  },
  destroyed() {
    //页面销毁，同时也销毁video.js对象
    if (this.player != null) {
      this.player.destroy(true);
    }
  },
  created() {

  },
  mounted() {
    if (this.sourceI != null) {
      this.initPlayer(this.sourceI, null);
    }
  },
  computed: {}
}
</script>

<style>

</style>